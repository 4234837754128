// Function to create a promise for each video's loaded state
function createVideoLoadPromise(video) {
    return new Promise((resolve) => {
        if (video.readyState >= 4) {
            resolve();
        } else {
            video.addEventListener('loadedmetadata', () => resolve(), { once: true });
        }
    });
}

// Function to load and play multiple videos
async function loadAndPlayVideos(videoIds) {
    const loadPromises = videoIds.map(createVideoLoadPromise);

    console.log(loadPromises)

    try {
        await Promise.all(loadPromises);
        videoIds.forEach(video => {
            console.log(video)
            video.play();
        });
    } catch (error) {
        console.error('Error loading videos:', error);
    }
}

// Usage
let videoIds = []; // Add your video element IDs here

Array.from(document.querySelectorAll('video.sk_parallax_bg_video')).forEach(video => {
    videoIds.push(video)
})

loadAndPlayVideos(videoIds);